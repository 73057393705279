import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq" id="faqs">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3 bluecolor">FAQ</span>
            Frequently Asked Questions</h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What is Remitano Clone Script?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Remitano Clone script is a customizable P2P Cryptocurrency exchange software for startups looking to develop a platform identical to Remitano. The software replicates the features and functionalities of the original Remitano platform.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                How does Remitano Clone work?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Remitano Clone works almost in a similar way to the Remitano platform. The trading functions and options are almost the same for both and don’t differ much. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                How much time will it take to launch an exchange platform using the clone software?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">A P2P Exchange like Remitano can be built within seven or ten days using our white-label software. However, for added customizations, the time to launch can exceed a little bit.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                What is the cost of a Remitano Clone Script?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The cost of a Remitano Clone usually starts from $10,000. For additional features and customizations, the cost could go up respectively.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                Why should you choose Coinsclone to build an exchange using the Clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Coinsclone is a reputed script provider with impeccable success so far in the Crypto industry. Our development solutions can satisfy criteria like affordability, time, and customizations.
                </div>
              </Accordion.Collapse>
            </div>

        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection