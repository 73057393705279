import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose</span>
            Coinsclone for acquiring Remitano Clone Script?</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <img width="535px" height="420px" src="https://coinsclone.mo.cloudinary.net/images/remitano-new/why-choose-coinsclone-for-acquring-remitano-clone-script.png" alt="Create Remitano Clone Script image1" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">We at Coinsclone are an eminent <span className='learn-more'><a href='https://www.coinsclone.com/cryptocurrency-exchange-development-company/'>cryptocurrency exchange software development company</a></span> with extraordinary success stories so far. Our clone scripts for popular exchanges have allowed entrepreneurs across the globe to launch an exchange business in an affordable way. We have been quite transparent in our development approach and offer long-term solutions for our clients. In fact, our exchange software with its admirable features has grabbed a lot of user attention and has paid rich dividends for the exchange owners. Our scalable solution can be a great boon for startups without a doubt and doesn’t require much capital investment. 
              </p>
              <p className="pharagraph">Secondly, we have all the customizable options for startups. Aspiring entrepreneurs who have excellent strategies and ideas in the Crypto industry have approached us and turned into millionaires in a matter of a few days. Our portfolio speaks more than what we can offer and we have more than seven plus years of experience in the blockchain industry. Having successfully completed 100+ Crypto projects, we have earned a huge amount of trust and reputation from our global clients so far. By shaking hands with Coinsclone, you can be guaranteed to have a stunning experience throughout the P2P exchange development process. 
              </p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose