import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import Slider from "react-slick";


class Testimonial extends React.Component {


  render() {

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };


    return (
      <section className="testimonial mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="testi-left">
                <h3 className="heading-h2"><span className="heading-h3 bluecolor">Our Customers</span>
                  Love what we do</h3>
                <p className="pharagraph" >Customer feedback and reviews are our major concerns! Here we have shared a few feedbacks of our reputed clients.
                </p>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 bluebg" >
              <div className="testi-right text-center">
                <Slider {...settings}>
                  <div className="testi-card">
                  <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance/montexx.png"
                      alt="montex"
                      width={142}
                    />
                    {/* <img width="142px" height="142px" src="https://coinsclone.mo.cloudinary.net/images/binance/montexx.png" alt="montex image1" /> */}
                    <p className="pharagraph mb-0">Project Name
                    </p>
                    <h3 className="bluecolor">MONTEX</h3>
                    <p className="pharagraph">The website developed by Coinsclone is user-friendly and the feedback from customers has been positive. They met all needs, while their expertise, professionalism, and ability to deliver on promises earned them trust.
                    </p>
                  </div>
                  <div className="testi-card">
                  <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance/naijaz.png"
                      alt="naijacrypto"
                      width={142}
                    />
                    {/* <img width="142px" height="142px" src="https://coinsclone.mo.cloudinary.net/images/binance/naijaz.png" alt="naijacrypto image1" /> */}
                    <p className="pharagraph mb-0">Project Name
                    </p>
                    <h3 className="bluecolor">NAIJACRYPTO</h3>
                    <p className="pharagraph">Seriously, these guys keep impressing me with this product and it keeps getting better and better all the time!
                    </p>
                  </div>
                  <div className="testi-card">
                  <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/binance/kowrii.png"
                      alt="kowrii"
                      width={142}
                    />
                    {/* <img width="142px" height="142px" src="https://coinsclone.mo.cloudinary.net/images/binance/kowrii.png" alt="kowrii image1" /> */}
                    <p className="pharagraph mb-0">Project Name
                    </p>
                    <h3 className="bluecolor">KOWRII EXCHANGE</h3>
                    <p className="pharagraph">Coinsclone team has strong skillset in developing Blockchain based applications. They did my project smoothly and efficiently in a timely manner. I am happy to work together with them!
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Testimonial