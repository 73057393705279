import * as React from "react"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Banner from "../components/Remitano/Banner"
import Whatis from "../components/Remitano/Whatis"
//import QuickviewSection from "../components/Remitano/Quickview"
import HowOur from "../components/Remitano/HowOur"
import TopFeatures from "../components/Remitano/TopFeatures"
// import CoreFeatures from "../components/Remitano/CoreFeatures"
import SecurityofOur from "../components/Remitano/SecurityofOur"
import TechStack from "../components/Remitano/TechStack"
import FaqSection from "../components/Remitano/FaqSection"
// import WhyShould from "../components/Remitano/WhyShould"
// import DevApproach from "../components/Remitano/DevApproach"
import WhyChoose from "../components/Remitano/WhyChoose"
import Addon from "../components/Remitano/Addon"
import OurAndroid from "../components/Remitano/OurAndroid"
import Testimonial from "../components/Clone/Testimonial"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"
import BenifitsOf from "../components/Remitano/Benifits"
import CaseStudy from "../components/Remitano/CaseStudy"





const Remitano = () => (
  <section className="landing clone remitano">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>Remitano Clone Script to create a p2p crypto exchange</title>
        <meta name="description" content="Remitano Clone script is a pre-built exchange software that includes the features and functionalities of a popular peer-to-peer platform like Remitano" />
        <meta name="keywords" content="White Label Remitano Clone Script" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Remitano Clone Script to create a p2p crypto exchange" />
        <meta property="og:description" content="Remitano Clone script is a pre-built exchange software that includes the features and functionalities of a popular peer-to-peer platform like Remitano" />
        <meta property="og:url" content="https://www.coinsclone.com/remitano-clone-script/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/white-label-remitano-clone-script-software.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Remitano Clone script is a pre-built exchange software that includes the features and functionalities of a popular peer-to-peer platform like Remitano" />
        <meta name="twitter:title" content="Remitano Clone Script to create a p2p crypto exchange" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/white-label-remitano-clone-script-software.png" />
        <link rel="canonical" href="https://www.coinsclone.com/remitano-clone-script/" />
      </Helmet>
      <Banner /> 
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Remitano Clone Script </span>
        </div>
      </div>
      <Whatis />
      <CaseStudy />
      {/* <QuickviewSection /> */}
      <HowOur />
      <TopFeatures />
      {/* <WhyShould /> */}
      {/* <CoreFeatures /> */}
      <SecurityofOur />
      <OurAndroid />
      <Addon />
      <BenifitsOf />
      <WhyChoose />
      {/* <DevApproach /> */}
      <Testimonial />
      <TechStack />
      <FaqSection />
      <UserFriendly />
      
    </Layout>
  </section>
)

export default Remitano
